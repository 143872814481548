<template lang="pug">
.collapse.mb-4.border.border-secondary.rounded-md
  input(type="checkbox" @click="display" class="peer w-full")
  .collapse-title.flex.items-center.justify-between.pr-4
    AtomsTitleWithLogo(:title="title" :icon="icon3ifa")
    AtomsIconMore(v-if="!displayMore").h-8.w-8
    AtomsIconLess(v-else).h-8.w-8
  div(class="lg:px-28").collapse-content.px-6
    div(v-if="changed").flex.items-center
      CalendarDaysIcon.h-8
      span.pl-2 Mise à jour: {{ useDateFormat(changed, 'DD/MM/YYYY')?.value }}
    AtomsDrupalRichText(v-if="description" :field="description")
    AtomsFormationsInfosDropdown(v-for="info in infos" :infos="info").pt-8
</template>

<script setup>
  import { useDateFormat } from '@vueuse/core'
  import { CalendarDaysIcon } from '@heroicons/vue/24/outline'
  import icon3ifa from '/assets/images/icon-3ifa.svg'
  // IMPORT USED FOR STORYBOOK
  import { ref } from 'vue'
  import AtomsDrupalRichText from '../drupal/RichText.vue'
  import AtomsTitleWithLogo from '../TitleWithLogo.vue'
  import AtomsIconMore from '../icon-more.vue'
  import AtomsIconLess from '../icon-less.vue'
  import AtomsFormationsInfosDropdown from './InfosDropdown.vue'

  const props = defineProps({
    title: { type: String, requried: true },
    infos: { type: Object, required: false },
    description: { type: Object, required: false },
    changed: { type: String, requried: false },
  })

  const displayMore = ref(false)

  const display = () => {
    displayMore.value = !displayMore.value
  }
</script>
